import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { rootUrl } from "../config";

export default function EditContactInformation(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [details, setDetails] = React.useState({loading: true, altered: false, rows: []})
  const [statusText, setStatusText] = React.useState("")

  if(details.loading) {
    axios.get(rootUrl + '/v1/persons/contact-details/' + id)
      .then(res => {
        console.log(res.data)
        setDetails({ ...details, rows: res.data, loading: false })
      })
      .catch(e => {
        console.log(e)
        setDetails({loading: false, altered: false})
      })
  }

  const save = () => {
    axios.post(rootUrl + '/v1/persons/contact-details/' +id +'/save', details)
      .then(() => {
        setDetails({...details, altered: false})
        setStatusText("Succesfully saved")

      })
      .catch(e => {
        console.log(e)
        setStatusText(e)
      })
  }

  const value = (index, newVal) => {
    const {rows} = details
    rows[index].value = newVal
    setDetails({...details, rows, altered: true})
  }

  const changeType = (index, newVal) => {
    const {rows} = details
    rows[index].type = newVal
    setDetails({...details, rows, altered: true})
  }

  const changeMode = (index, newVal) => {
    const {rows} = details
    rows[index].mode = newVal
    setDetails({...details, rows, altered: true})
  }



  const newContactInfo = () => {
    let newContactInfoEntry = {
      mode: 'HOME',
      type: 'EMAIL',
      value: "",
      version: details.rows.length + 1,
      person_id: id
    }
    setDetails({...details, rows: [...details.rows, newContactInfoEntry]})
  }

  return <div>
    <div className="App" style={{display: 'flex', justifyContent: 'space-evenly', padding: 70, flexDirection: 'column', gap: 20}}>
      <Typography variant={'h2'}>LPC Data Entry</Typography> <br/>
      <Typography variant={'h5'}>View and edit contact information</Typography> <br/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
        <a href="/">Search</a> -
        <a href={'/edit-personal-information/' + id}>Personal Details</a> -
        <a href={"/edit-address-information/" + id}>Address information</a> -
        <a href={"/edit-contact-information/" +id }>Contact information</a> -
        <a href={"/edit-camp-attendance/" +id }>Camp attendance</a>
      </div>

      {details.rows.map((row, i) => {
        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
          <FormControl>
            <InputLabel id="demo-simple-select-label2">Description</InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              label="Description"
              value={row.mode} onChange={(e) => changeMode(i, e.target.value)}
            >
              <MenuItem value={"HOME"}>Home</MenuItem>
              <MenuItem value={"WORK"}>Work</MenuItem>
              <MenuItem value={"UNdefined"}>Undefined</MenuItem>

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label4">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label4"
              label="Type"
              value={row.type} onChange={(e) => changeType(i, e.target.value)}
            >
              <MenuItem value={"EMAIL"}>Email</MenuItem>
              <MenuItem value={"PHONE"}>Phone</MenuItem>
              <MenuItem value={"MOBILE"}>Mobile</MenuItem>

              <MenuItem value={"FAX"}>Fax</MenuItem>

            </Select>
          </FormControl>
          <TextField label={"Value"} value={row.value} onChange={e => value(i, e.target.value)} style={{minWidth: 400}}></TextField>

        </div>
      })}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} onClick={newContactInfo}>Add a new contact info entry</Button>

      </div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} color={'success'} disabled={!details.altered} onClick={save}>Save</Button>
        <Button variant={'contained'} onClick={() => navigate('/edit-camp-attendance/' +id)}>Next step ></Button>

      </div>
      <Typography>{statusText}</Typography>

    </div>
  </div>

}
