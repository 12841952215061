import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { rootUrl } from "../config";

export default function EditCampAttendance(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [details, setDetails] = React.useState({loading: true, altered: false, rows: []})
  const [statusText, setStatusText] = React.useState("")

  if(details.loading) {
    axios.get(rootUrl + '/v1/persons/camp-attendance/' + id)
      .then(res => {
        console.log(res.data)
        setDetails({ ...details, rows: res.data.rows, camps: res.data.camps, loading: false })
      })
      .catch(e => {
        console.log(e)
        setDetails({loading: false, altered: false})
      })
  }

  const save = () => {
    axios.post(rootUrl + '/v1/persons/camp-attendance/' +id +'/save', details)
      .then(() => {
        setDetails({...details, altered: false})
        setStatusText("Succesfully saved")

      })
      .catch(e => {
        console.log(e)
        setStatusText(e)
      })
  }

  const value = (index, newVal) => {
    const {rows} = details
    rows[index].value = newVal
    setDetails({...details, rows, altered: true})
  }

  const changeLocation = (index, newVal) => {
    const {rows} = details
    rows[index].camp_id = newVal
    setDetails({...details, rows, altered: true})
  }

  const changeStatus = (index, newVal) => {
    const {rows} = details
    rows[index].status = newVal
    setDetails({...details, rows, altered: true})
  }


  const newAttendance = () => {
    let newContactInfoEntry = {
      status: 'CAmper',
      camp_id: 1,
      version: details.rows.length + 1,
      person_id: id
    }
    setDetails({...details, rows: [...details.rows, newContactInfoEntry]})
  }


  const notes = (index, newVal) => {
    const {rows} = details
    rows[index].notes = newVal
    setDetails({...details, rows, altered: true})
  }

  return <div>
    <div className="App" style={{display: 'flex', justifyContent: 'space-evenly', padding: 70, flexDirection: 'column', gap: 20}}>
      <Typography variant={'h2'}>LPC Data Entry</Typography> <br/>
      <Typography variant={'h5'}>View and edit contact information</Typography> <br/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
        <a href="/">Search</a> -
        <a href={'/edit-personal-information/' + id}>Personal Details</a> -
        <a href={"/edit-address-information/" + id}>Address information</a> -
        <a href={"/edit-contact-information/" +id }>Contact information</a> -
        <a href={"/edit-camp-attendance/" +id }>Camp attendance</a>
      </div>

      {details.rows.map((row, i) => {
        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
          <FormControl>
            <InputLabel id="demo-simple-select-label2">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              label="Description"
              value={row.status} onChange={(e) => changeStatus(i, e.target.value)}
            >
              <MenuItem value={"CAmper"}>Camper</MenuItem>
              <MenuItem value={"COuncellor"}>Councellor</MenuItem>
              <MenuItem value={"Director"}>Director</MenuItem>
              <MenuItem value={"Invited"}>Invited</MenuItem>
              <MenuItem value={"DITraining"}>DIT</MenuItem>
              <MenuItem value={"CoDirector"}>CoDirector</MenuItem>

              <MenuItem value={"UNdefined"}>Undefined</MenuItem>

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label4">Campsite</InputLabel>
            <Select
              labelId="demo-simple-select-label4"
              label="Campsite"
              value={row.camp_id} onChange={(e) => changeLocation(i, e.target.value)}
            >
              {
                details.camps.map((camp, i) => {
                  return <MenuItem value={camp.id}>{camp.location}</MenuItem>
                })
              }



            </Select>
          </FormControl>

          <TextField label={"Notes"} value={row.notes} onChange={e => notes(i, e.target.value)} style={{minWidth: 400}}></TextField>


        </div>
      })}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} onClick={newAttendance}>Add a new camp attendance entry</Button>

      </div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} color={'success'} disabled={!details.altered} onClick={save}>Save</Button>
      </div>
      <Typography>{statusText}</Typography>

    </div>
  </div>

}
