import {
  Box,
  Button, FormControl, InputLabel, MenuItem,
  Paper, Select,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { rootUrl } from "../config";
import { useNavigate } from "react-router-dom";

export default function NewPerson(props) {
  const [details, setDetails] = React.useState({})
  const navigate = useNavigate()

  const save = () => {
    axios.post(rootUrl + '/v1/persons/save', { ...details, version: 0, status: "COuncellor" }).then(res => {
      console.log("navigating to " + res.data.id)
      navigate('/edit-personal-information/' + res.data.id)
    }).catch(e => {
      console.log(e)
    })
  }

  return <div>
    <div className="App" style={{display: 'flex', justifyContent: 'center', padding: 70, flexDirection: 'column'}}>
      <Typography variant={'h2'}>LPC Data Entry</Typography> <br/>
      <Typography variant={'h5'}>Create a new person</Typography> <br/>

      <Box component='form' sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}>
        <TextField label="First Name" value={details.firstName} onChange={(e) => setDetails({...details, firstName: e.target.value })}/>
        <TextField label="Last Name" value={details.lastName} onChange={(e) => setDetails({...details, lastName: e.target.value})}/>
        <DatePicker label="Birthday" value={details.birthday} onChange={(e) => setDetails({...details, birthday: e})}/>
        <br/>
        <FormControl style={{minWidth: 120, padding: 8}}>
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Gender"
            value={details.gender} onChange={(e) => setDetails({ ...details, gender: e.target.value })}
          >
            <MenuItem value={"MALE"}>Male</MenuItem>
            <MenuItem value={"FEMALE"}>Female</MenuItem>
            <MenuItem value={"UNdefined"}>Undefined</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Preferences" value={details.preferences} onChange={(e) => setDetails({...details, preferences: e.target.value})}></TextField>
        <TextField label="Parent names" value={details.parentNames} onChange={(e) => setDetails({...details, parentNames: e.target.value})}></TextField>
      </Box>
      <br/>
    </div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
      <Button variant={'contained'} onClick={save}>Save and proceed to next step ></Button>

    </div>
  </div>;

}
