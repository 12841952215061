import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem, Paper,
  Select, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import axios from "axios";
import { rootUrl } from "../config";
import { useNavigate } from "react-router-dom";

export default function Search(props) {
  const navigate = useNavigate()
  const [toggle, setToggle] = React.useState(false)
  const [firstName, setFirstName] = React.useState("")
  const [id, setId] = React.useState()
  const [lastName, setLastName] = React.useState("")
  const [gender, setGender] = React.useState()
  const [birthday, setBirthday] = React.useState()
  const [findRequest, setFindRequest] = React.useState([])


  const find = () => {
    axios.post(rootUrl + '/v1/persons/find', {firstName, lastName}).then((result) => {
      setFindRequest(result.data)
    })
      .catch(e => {
        console.log(e)
      })
  }

  const select = (row) => {
    setId(row.id)
  }


  return <div>
    <div className="App" style={{display: 'flex', justifyContent: 'center', padding: 70, flexDirection: 'column'}}>
      <Typography variant={'h2'}>LPC Data Entry</Typography> <br/>
      <Typography variant={'h5'}>Find an existing person</Typography> <br/>

      <Box component='form' sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}>
        <TextField label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
        <TextField label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
        <Button style={{margin: 14}} variant={"contained"} size={'large'} onClick={find}>Search</Button>
      </Box>
      <br/>

      <TableContainer component={Paper} elevation={0} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Date of Birth</TableCell>
              <TableCell align="left">Gender</TableCell>
              <TableCell align="left">Parent names</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {findRequest.map((row) => (
              <TableRow
                key={row.firstName+row.lastName}
                style={{backgroundColor: id == row.id ? 'grey': ''}}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.firstName} {row.lastName}
                </TableCell>
                <TableCell align="left">{new Date(row.birthDay).toLocaleDateString()}</TableCell>
                <TableCell align="left">{row.gender}</TableCell>
                <TableCell align="left">{row.parents}</TableCell>
                <TableCell align="right"><Button onClick={() => select(row)}>select</Button></TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
      <Button variant={'contained'} onClick={() => navigate('/new-person')}>Create new entry ></Button>
      <Button variant={'contained'} disabled={!id} color={'success'} onClick={() => navigate('/edit-personal-information/' + id)}>Proceed with selected person ></Button>

    </div>
  </div>
}
