import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { rootUrl } from "../config";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function EditPersonalInfo(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [details, setDetails] = React.useState({loading: true, altered: false, rows: []})
  const [statusText, setStatusText] = React.useState("")

  if(details.loading) {
    axios.get(rootUrl + '/v1/persons/personal-details/' + id)
      .then(res => {
        setDetails({ ...res.data.info, rows: res.data.rows, countries: res.data.countries, birth_day: dayjs(res.data.info.birth_day) })
      })
      .catch(e => {
        console.log(e)
        setDetails({loading: false, altered: false, rows: []})
      })
  }

  const save = () => {
    axios.post(rootUrl + '/v1/persons/save-personal-details/' + id, details)
      .then(() => {
        setDetails({...details, altered: false})
        setStatusText("Succesfully saved")

      })
      .catch(e => {
        console.log(e)
        setStatusText(e)
      })
  }

  const changeNationality = (index, newId) => {
    const {rows} = details
    rows[index].country_id = newId
    setDetails({...details, rows, altered: true})
  }

  const addNationality = () => {
    let newAddress = {
      version: details.rows.length + 1,
      person_id: id
    }
    setDetails({...details, rows: [...details.rows, newAddress]})
  }

  return <div>
    <div className="App" style={{display: 'flex', justifyContent: 'space-evenly', padding: 70, flexDirection: 'column', gap: 20}}>
      <Typography variant={'h2'}>LPC Data Entry</Typography> <br/>
      <Typography variant={'h5'}>View and edit personal details</Typography> <br/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
        <a href="/">Search</a> -
        <a href={'/edit-personal-information/' + id}>Personal Details</a> -
        <a href={"/edit-address-information/" + id}>Address information</a> -
        <a href={"/edit-contact-information/" +id }>Contact information</a> -
        <a href={"/edit-camp-attendance/" +id }>Camp attendance</a>
      </div>

      <TextField label="First name" value={details.first_name || ""} onChange={e => setDetails({...details, first_name: e.target.value, altered: true})}></TextField>
      <TextField label="Last name" value={details.last_name || ""} onChange={e => setDetails({...details, last_name: e.target.value, altered: true})}></TextField>
      <TextField label="Parent name(s)" value={details.parent_name || ""} onChange={e => setDetails({...details, parent_name: e.target.value, altered: true})}></TextField>
      <TextField label="Preferences" value={details.preferences || ""} onChange={e => setDetails({...details, preferences: e.target.value, altered: true})}></TextField>

      <DatePicker label="Birthday" value={details.birth_day} onChange={(e) => setDetails({...details, birth_day: e, altered: true})}/>


      <FormControl>
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-labelBla"
          label="Gender"
          value={details.gender || "MALE"} onChange={(e) => setDetails({ ...details, gender: e.target.value, altered: true })}
        >
          <MenuItem value={"MALE"}>Male</MenuItem>
          <MenuItem value={"FEMALE"}>Female</MenuItem>
          <MenuItem value={"UNdefined"}>Undefined</MenuItem>
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Status"
          value={details.status || "COuncellor"} onChange={(e) => setDetails({ ...details, status: e.target.value, altered: true })}
        >
          <MenuItem value={"COuncellor"}>Councellor</MenuItem>
          <MenuItem value={"Director"}>Director</MenuItem>
          <MenuItem value={"CoDirector"}>Co-Director</MenuItem>
          <MenuItem value={"CAmper"}>Camper</MenuItem>
          <MenuItem value={"UNdefined"}>Undefined</MenuItem>

        </Select>
      </FormControl>


      {details.rows.map((row, i) => {
        return <FormControl>
          <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Country"
            value={row.country_id}
            onChange={e => changeNationality(i, e.target.value)}
          >
            {details.countries.map(country => {
              return <MenuItem value={country.id}>{country.name}</MenuItem>
            })
            }
          </Select>
        </FormControl>
      })}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} onClick={addNationality}>Add new nationality</Button>

      </div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} color={'success'} disabled={!details.altered} onClick={save}>Save</Button>
        <Button variant={'contained'} onClick={() => navigate('/edit-address-information/' +id)}>Next step ></Button>

      </div>
      <Typography>{statusText}</Typography>

    </div>
  </div>

}
