
import React, { Fragment } from "react";
import axios from "axios";
import sha256 from "sha256";
import { rootUrl } from "../config";
import { Button, TextField } from "@mui/material";

export default function Register(props) {
  const [disabled, setDisabled] = React.useState(false)
  const [password, setPassword] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState(false)

  const onSubmitFunction = () => {
    if(password.length > 1 && email.length > 1) {
      setDisabled(true)
      axios.post(rootUrl + '/v1/users/register', {username: email, password: sha256(password)})
        .then(res => {
          setErrorMessage(res.data)

          console.log(res)
        })
        .catch(e => {
          console.log(e)
          setDisabled(false)
          setErrorMessage(e.response.data)
        })
    } else {
      setErrorMessage("Username or password too short")
    }
  }

  return (
    <Fragment>
      <div fluid style={{display: 'flex', justifyContent: 'center', padding: 400, paddingTop: 200, backgroundColor: 'grey'}}>
        <div style={{border: '2px solid grey', borderRadius: '20px', padding: 100, backgroundColor: 'white',}}>
              <TextField disabled={disabled} type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)}/>


              <TextField disabled={disabled} type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
            <Button disabled={disabled} onClick={onSubmitFunction} variant="outline-secondary">
              Register
            </Button>
            {errorMessage && <span style={{marginLeft:10}}>{errorMessage}</span>}

        </div>
      </div>
    </Fragment>

  )
}

