import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Search from "./screens/Search";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import { rootUrl } from './config'
import NewPerson from "./screens/NewPerson";
import EditPersonalInfo from "./screens/EditPersonalInfo";
import EditAddressInformation from "./screens/EditAddressInformation";
import EditContactInformation from "./screens/EditContactInformation";
import EditCampAttendance from "./screens/EditCampAttendance";
import Login from "./screens/Login";
import Register from "./screens/Registering";

function NotFound () {
  return  <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
    <span className={'loader'}></span>
    <h1 style={{margin: 20}}>Page not found. Sorry!</h1>
  </div>
}


function App() {
  const [authenticationRequest, setAuthenticationRequest] = React.useState(false)
  if(!authenticationRequest) {
    axios.get(rootUrl + '/v1/users/isSessionValid')
      .then(res => {
        setAuthenticationRequest({ authenticated: true, loading: false, registering: false })
      })
      .catch(e => {
        setAuthenticationRequest({ authenticated: false, loading: false, registering: false })
      })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        {!authenticationRequest.authenticated && !authenticationRequest.registering && <Login registerCallback={() => setAuthenticationRequest({...authenticationRequest, registering: true})}/>}
        {!authenticationRequest.authenticated && authenticationRequest.registering && <Register/>}

        {authenticationRequest.authenticated &&

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Search/>}/>
              <Route path="/new-person" element={<NewPerson></NewPerson>}></Route>
              <Route path="/edit-personal-information/:id" element={<EditPersonalInfo></EditPersonalInfo>}></Route>
              <Route path="/edit-address-information/:id" element={<EditAddressInformation></EditAddressInformation>}></Route>
              <Route path="/edit-contact-information/:id" element={<EditContactInformation></EditContactInformation>}></Route>
              <Route path="/edit-camp-attendance/:id" element={<EditCampAttendance></EditCampAttendance>}></Route>

              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </BrowserRouter>
        }
      </div>
    </LocalizationProvider>

  );
}

export default App;
