import axios from "axios";
import React from 'react'
import { rootUrl } from "../config";
import sha256 from 'sha256';
import { Button, TextField } from "@mui/material";

export default function Login(props) {
  const [disabled, setDisabled] = React.useState(false)
  const [password, setPassword] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState(false)

  const onSubmitFunction = () => {
    if(password.length > 1 && email.length > 1) {
      setDisabled(true)

      axios.post(rootUrl + '/v1/users/login', {username: email, password: sha256(password)})
        .then(res => {
          console.log(res)
          window.location.reload()
        })
        .catch(e => {
          console.log(e)
          setDisabled(false)
          setErrorMessage(e.response.data)
        })

    } else {
      setErrorMessage("Username or password too short")
    }
  }

  return (
    <div>
      <div fluid style={{display: 'flex', justifyContent: 'center', padding: 400, paddingTop: 200, backgroundColor: 'grey'}}>
        <div style={{border: '2px solid grey', borderRadius: '20px', padding: 100, backgroundColor: 'white',}}>
            <TextField disabled={disabled} label={"Email"} value={email} onChange={e => setEmail(e.target.value)}></TextField>

            <TextField disabled={disabled} type="password" value={password} onChange={e => setPassword(e.target.value)}/>
            <Button disabled={disabled} onClick={onSubmitFunction} variant="outline-secondary">
              Submit
            </Button>
            {/*<Button onClick={() => props.registerCallback()}>Register</Button>*/}
            {errorMessage && <span style={{marginLeft:10}}>{errorMessage}</span>}

        </div>
      </div>
    </div>

  )
}
