import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { rootUrl } from "../config";

export default function EditAddressInformation(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [details, setDetails] = React.useState({loading: true, altered: false, rows: []})
  const [statusText, setStatusText] = React.useState("")

  if(details.loading) {
    axios.get(rootUrl + '/v1/persons/address-details/' + id)
      .then(res => {
        console.log(res.data)
        setDetails(res.data)
      })
      .catch(e => {
        console.log(e)
        setDetails({loading: false, altered: false})
      })
  }

  const save = () => {
    axios.post(rootUrl + '/v1/persons/address-details/' +id +'/save', details)
      .then(() => {
        setDetails({...details, altered: false})
        setStatusText("Succesfully saved")

      })
      .catch(e => {
        console.log(e)
        setStatusText(e)
      })
  }

  const street1 = (index, newVal) => {
    const {rows} = details
    rows[index].street1 = newVal
    setDetails({...details, rows, altered: true})
  }
  const street2 = (index, newVal) => {
    const {rows} = details
    rows[index].street2 = newVal
    setDetails({...details, rows, altered: true})
  }
  const zipcode = (index, newVal) => {
    const {rows} = details
    rows[index].zip_code = newVal
    setDetails({...details, rows, altered: true})
  }
  const city = (index, newVal) => {
    const {rows} = details
    rows[index].city = newVal
    setDetails({...details, rows, altered: true})
  }

  const changeCountry = (index, newId) => {
    const {rows} = details
    rows[index].country_id = newId
    setDetails({...details, rows})
  }

  const newAddress = () => {
    let newAddress = {
      street1: "",
      street2: "",
      city: "",
      country_id: 1,
      zip_code: "",
      version: details.rows.length + 1,
      person_id: id
    }
    setDetails({...details, rows: [...details.rows, newAddress]})
  }

  return <div>
    <div className="App" style={{display: 'flex', justifyContent: 'space-evenly', padding: 70, flexDirection: 'column', gap: 20}}>
      <Typography variant={'h2'}>LPC Data Entry</Typography> <br/>
      <Typography variant={'h5'}>View and edit personal details</Typography> <br/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
        <a href="/">Search</a> -
        <a href={'/edit-personal-information/' + id}>Personal Details</a> -
        <a href={"/edit-address-information/" + id}>Address information</a> -
        <a href={"/edit-contact-information/" +id }>Contact information</a> -
        <a href={"/edit-camp-attendance/" +id }>Camp attendance</a>
      </div>

      {details.rows.map((row, i) => {
        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
          <TextField label={"Street 1"} value={row.street1} onChange={e => street1(i, e.target.value)}></TextField>
          <TextField label={"Street 2"} value={row.street2} onChange={e => street2(i, e.target.value)}></TextField>
          <TextField label={"City"} value={row.city} onChange={e => city(i, e.target.value)}></TextField>
          <TextField label={"Zipcode"} value={row.zip_code} onChange={e => zipcode(i, e.target.value)}></TextField>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Country"
              value={row.country_id}
              onChange={e => changeCountry(i, e.target.value)}
            >
              {details.countries.map(country => {
                return <MenuItem value={country.id}>{country.name}</MenuItem>
              })
              }
            </Select>
          </FormControl>

        </div>
      })}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} onClick={newAddress}>Add a new address entry</Button>

      </div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10}}>
        <Button variant={'contained'} color={'success'} disabled={!details.altered} onClick={save}>Save</Button>
        <Button variant={'contained'} onClick={() => navigate('/edit-contact-information/' +id)}>Next step ></Button>

      </div>
      <Typography>{statusText}</Typography>

    </div>
  </div>

}
